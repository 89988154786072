import React from 'react';
import {
  Row,
  Col,
  Input,
  Radio,
  Form,
  Alert,
  Spin,
  Tooltip,
  Icon,
  Modal,
  Checkbox,
  Button,
} from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';

import './styles.scss';
import StepCard from 'components/StepCard';
import { formatAmountToNL } from 'util/helpers/normalizer';
import { history } from 'util/helpers/history';
import { DELAY_SECONDS } from 'constants/index';
import { ReactComponent as CalculateSvg } from 'assets/get.svg';

let timer;

class AnnualTarifChoices extends React.Component {
  state = {
    milieu: null,
    marina: null,
    night: null,
    removeOpacity: false,
    visible: true,
    timeout: null,
    showModal: false,
    checkbox: false,
  };

  onSelectMilieu = e => {
    this.setState({
      milieu: e.target.value,
    });

    this.props.clearData();
  };

  onSelectMarina = e => {
    this.setState({
      marina: e.target.value,
      removeOpacity: true,
    });
    this.props.clearData();
  };

  onSelectNight = e => {
    this.setState({
      night: e.target.value,
    });
    this.setState({
      removeOpacity: true,
    });
    this.props.clearData();
  };

  validNumber = (rule, value, callback) => {
    const { form } = this.props;
    const regex = /^[0-9.,]*$/;
    if (regex.test(value)) {
      callback();
    } else {
      callback(<FormattedMessage id="not_valid_field" />);
    }
    this.props.clearData();
  };

  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        sessionStorage.setItem('annual_tarif_form', JSON.stringify(values));
        this.setState({ visible: true });
        const data = {
          milieu: values.milieu,
          marina: values.marina,
          length: values.length.replace(',', '.'),
          width: values.width.replace(',', '.'),
        };
        this.props.getTarifChoices(data);
      }
    });
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, DELAY_SECONDS);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  renderDocumentInfo = () => {
    const { data } = this.props;
    if (data && data.documents) {
      return (
        <div>
          <p>
            <FormattedHTMLMessage id="buy.vignet.document.info.title" />
          </p>
          <p>
            <FormattedHTMLMessage id="buy.vignet.document.info" />
          </p>
          <ul>
            {data.documents.map(doc => (
              <li style={{ listStyleType: 'square' }}>
                {doc !== 'Ontheffing' ? (
                  <React.Fragment>
                    {doc}{' '}
                    <Tooltip
                      title={
                        <FormattedHTMLMessage id="buy.vignet.document.tooltip" />
                      }
                    >
                      <Icon type="info-circle" className="info-icon-wrapper" />
                    </Tooltip>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {doc}{' '}
                    <a
                      href="https://www.amsterdam.nl/veelgevraagd/?caseid=%7B4669F01E-2601-42E9-90AD-101BAB243A88%7D"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (<FormattedMessage id="buy.vignet.click.here" />)
                    </a>
                  </React.Fragment>
                )}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  renderModal = () => {
    return (
      <Modal
        visible={this.state.showModal}
        onCancel={() => this.setState({ showModal: false })}
        footer={null}
        width={720}
      >
        <div className="modal-wrapper">
          <p className="modal-title">
            {this.props.data &&
              this.props.data.vignet_type === 'Abonnement' && (
                <FormattedMessage id="buy.vignet.tarif.modal.title" />
              )}
          </p>
          <p className="modal-title">
            {this.props.data && this.props.data.vignet_type === 'Milieu' && (
              <FormattedMessage id="buy.vignet.tarif.milieu.choice.modal.title" />
            )}
          </p>
          <p className="modal-title">
            {this.props.data && this.props.data.vignet_type === 'Hybride' && (
              <FormattedMessage id="buy.vignet.tarif.modal.hybride.title" />
            )}
          </p>
          <p className="modal-instruction">
            {this.props.data &&
              this.props.data.vignet_type === 'Abonnement' && (
                <FormattedHTMLMessage id="buy.vignet.tarif.modal.description" />
              )}
          </p>
          <p className="modal-instruction">
            {this.props.data && this.props.data.vignet_type === 'Milieu' && (
              <FormattedHTMLMessage id="buy.vignet.tarif.modal.milieu.description" />
            )}
          </p>
          <p className="modal-instruction">
            {this.props.data && this.props.data.vignet_type === 'Hybride' && (
              <FormattedHTMLMessage id="buy.vignet.tarif.modal.hybdride.description" />
            )}
          </p>
          <div style={{ marginTop: '20px' }}>
            <Checkbox
              onChange={() => this.setState({ checkbox: !this.state.checkbox })}
            >
              <FormattedMessage id="buy.vignet.tarif.modal.checkbox" />
            </Checkbox>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button
              type="primary"
              disabled={!this.state.checkbox}
              onClick={() => {
                this.setState({ showModal: false });
                this.goToChooseAccount();
              }}
            >
              <FormattedMessage id="CONFIRM" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  goToChooseAccount = () => {
    sessionStorage.removeItem('counter');
    sessionStorage.removeItem('request_start_date');
    history.push('/buy/vignette/annual/choose-account');
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data } = this.props;
    let formData = sessionStorage.getItem('annual_tarif_form');
    if (!formData) {
      formData = {};
    } else {
      formData = JSON.parse(formData);
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          onPrevious={() => history.goBack()}
          onNext={() =>
            // this.setState({ showModal: true })
            this.props.data &&
            ['Abonnement', 'Milieu', 'Hybride'].includes(
              this.props.data.vignet_type,
            )
              ? this.setState({ showModal: true })
              : this.goToChooseAccount()
          }
          current={0}
          steps={7}
          id="buy.vignete.instruction.title"
          className="buy-vignet-tarif-choices"
          isNextDisabled={!this.props.data}
        >
          <Row className="wrapper">
            <div>
              <p className="title">
                <FormattedMessage id="buy.vignet.tarif.choices.title" />
              </p>
            </div>

            <Col span={24} xs={24} lg={12} md={24} sm={24}>
              <p className="main-question-title">
                <FormattedMessage id="buy.vignet.tarif.choices.instruction.title" />
              </p>
              <Form.Item className="form-wrapper">
                <p className="tarif-question-label">
                  <FormattedMessage id="buy.vignet.tarif.choices.instruction.description" />
                </p>
                {getFieldDecorator('milieu', {
                  initialValue: formData.milieu,
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                  ],
                })(
                  <Radio.Group
                    onChange={this.onSelectMilieu}
                    value={this.state.milieu}
                  >
                    <Row>
                      <Col
                        span={24}
                        xs={24}
                        lg={24}
                        md={24}
                        sm={24}
                        className="radio-button-item"
                      >
                        <Radio value={1}>
                          <FormattedMessage id="buy.vignet.tarif.boat.option.electrical" />
                        </Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        span={24}
                        xs={24}
                        lg={24}
                        md={24}
                        sm={24}
                        className="radio-button-item"
                      >
                        <Radio value={0}>
                          <FormattedMessage id="buy.vignet.tarif.boat.option.other" />
                        </Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        span={24}
                        xs={24}
                        lg={24}
                        md={24}
                        sm={24}
                        className="radio-button-item"
                      >
                        <Radio value={2}>
                          <FormattedMessage id="buy.vignet.tarif.boat.option.both" />
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>,
                )}
              </Form.Item>
              <Form.Item className="form-wrapper">
                <p className="tarif-question-label">
                  <FormattedMessage id="buy.vignet.tarif.boat.size" />
                </p>
                <Row>
                  <Col
                    span={24}
                    xs={24}
                    lg={8}
                    md={24}
                    sm={24}
                    className="form-wrapper"
                  >
                    <label>
                      <FormattedMessage id="extend.rate.length" />
                    </label>
                    <Form.Item>
                      {getFieldDecorator('length', {
                        initialValue: formData.length,
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="required.error" />,
                          },
                          {
                            validator: this.validNumber,
                          },
                        ],
                      })(<Input suffix="m" />)}
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} lg={1} md={24} sm={24}></Col>
                  <Col span={24} xs={24} lg={8} md={24} sm={24}>
                    <label>
                      <FormattedMessage id="extend.rate.width" />
                    </label>
                    <Form.Item>
                      {getFieldDecorator('width', {
                        initialValue: formData.width,
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="required.error" />,
                          },
                          {
                            validator: this.validNumber,
                          },
                        ],
                      })(<Input suffix="m" />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item className="form-wrapper">
                <p className="tarif-question-label">
                  <FormattedMessage id="buy.vignet.tarif.place.label" />
                </p>
                {getFieldDecorator('marina', {
                  initialValue: formData.marina,
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                  ],
                })(
                  <Radio.Group
                    onChange={this.onSelectMarina}
                    value={this.state.marina}
                  >
                    <Row>
                      <Col
                        span={24}
                        xs={24}
                        lg={24}
                        md={24}
                        sm={24}
                        className="radio-button-item"
                      >
                        <Radio value={1}>
                          <FormattedMessage id="yes" />
                        </Radio>
                      </Col>
                      <Col
                        span={24}
                        xs={24}
                        lg={24}
                        md={24}
                        sm={24}
                        className="radio-button-item"
                      >
                        <Radio value={0}>
                          <FormattedMessage id="no" />
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Col>
            <Col span={24} xs={24} lg={5} md={24} sm={24}>
              {this.props.isRequesting ? (
                <Spin size="large" />
              ) : (
                <div onClick={this.handleSubmit}>
                  <div
                    className="calculate-wrapper"
                    style={this.state.removeOpacity ? { opacity: 1 } : null}
                  >
                    <CalculateSvg className="calculate-svg" />
                    <p style={{ fontFamily: 'avenir' }}>
                      <FormattedMessage id="calculate" />
                    </p>
                  </div>
                </div>
              )}
            </Col>
            <Col span={24} xs={24} lg={6} md={24} sm={24}>
              <label>
                <FormattedMessage id="buy.vignet.tarif.boat.surface" />
              </label>
              <Form.Item>
                <Input
                  disabled
                  value={data ? formatAmountToNL(data.surface) : null}
                  {...(data
                    ? {
                        suffix: (
                          <span>
                            m<sup>2</sup>
                          </span>
                        ),
                      }
                    : {})}
                  style={{ fontWeight: 'bolder' }}
                />
              </Form.Item>
              <label>
                <FormattedMessage id="vignet_type" />
              </label>
              <Form.Item>
                <Input disabled value={data ? data.vignet_type : null} />
              </Form.Item>
              <label>
                <FormattedMessage id="request.overview.amount.toPay" />
              </label>
              <Form.Item>
                <Input
                  disabled
                  {...(data ? { prefix: <span>&#8364;</span> } : {})}
                  value={data ? formatAmountToNL(data.vignet_cost) : null}
                />
              </Form.Item>
              {this.renderDocumentInfo()}
              {this.renderServerErrorMsg()}
              {this.renderModal()}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}
const AnnualTarifChoicesForm = Form.create({ name: 'AnnualTarifChoicesForm' })(
  AnnualTarifChoices,
);
export default AnnualTarifChoicesForm;
