import { call, put, takeLeading } from 'redux-saga/effects';

import { Creators, Types } from '../../redux/buyVignette/actions';
import {
  buyVigneteGetTarifChoices as buyVigneteGetTarifChoicesApi,
  buyVigneteLogin as buyVigneteLoginApi,
  buyVigneteCreateAccount as buyVigneteCreateAccountApi,
  buyVigneteGetOwner as buyVigneteGetOwnerApi,
  buyVignetUpdateOwner as buyVignetUpdateOwnerApi,
  buyGetObjectTypes as buyGetObjectTypesApi,
  buyGetDriveTypes as buyGetDriveTypesApi,
  buyVignetCreateDayRequest as buyVignetCreateDayRequestApi,
  buyGetBankLists as buyGetBankListsApi,
  buyMakeDayPayment as buyMakeDayPaymentApi,
  buyGetOverview as buyGetOverviewApi,
  buyUploadDocuments as buyUploadDocumentsApi,
  buyHasMandate as buyHasMandateApi,
  buyCreateMandate as buyCreateMandateApi,
  buyGetInstallments as buyGetInstallmentsApi,
  buyGetVignetCost as buyGetVignetCostApi,
  buyCreateDirect as buyCreateDirectApi,
  buyCreateInstallment as buyCreateInstallmentApi,
  buyCheckDayRequest as buyCheckDayRequestApi,
  buyGetVignetConfirmation as buyGetVignetConfirmationApi,
  buyVignetForgotPassword as buyVignetForgotPasswordApi,
  buyVignetResetPassword as buyVignetResetPasswordApi,
} from '../../api/buyVignette';
import { CURRENT_URL, DELAY_SECONDS } from 'constants/index';
import { logOut, timeStampDifference } from 'util/helpers/normalizer';
import { history } from 'util/helpers/history';

export function* getTarifChoices(actions) {
  try {
    const { data } = actions;
    let counter = Number(sessionStorage.getItem('counter'));
    if (counter === 0) {
      sessionStorage.setItem('request_start_date', new Date().getTime());
    }
    sessionStorage.setItem('counter', counter + 1);
    let start_spamming = sessionStorage.getItem('request_start_date');
    const now = new Date().getTime();
    let timeDifference = timeStampDifference(now, Number(start_spamming));
    if (timeDifference > 60000) {
      sessionStorage.removeItem('counter');
      sessionStorage.removeItem('request_start_date');
    } else if (counter >= 10 && timeDifference < 60000) {
      throw Error(
        `'De knop ‘Bereken prijs’ is geblokkeerd. Misschien hebt u te vaak in een korte periode op de knop gedrukt of ging er iets fout in ons systeem. Probeer het over een minuut opnieuw`,
      );
    }
    const response = yield call(buyVigneteGetTarifChoicesApi, data);
    yield put(Creators.buyVignetGetTarifChoicesSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    if (error && error.message) {
      error_message = error.message;
    }
    yield put(Creators.buyVignetGetTarifChoicesFailure(error_message));
  }
}

export function* watchGetTarifChoices() {
  yield takeLeading(
    Types.BUY_VIGNET_GET_TARIF_CHOICES_REQUEST,
    getTarifChoices,
  );
}

export function* logIn(actions) {
  try {
    const { data } = actions;
    const tarifChoice = JSON.parse(sessionStorage.getItem('tarifChoices'));
    const response = yield call(buyVigneteLoginApi, {
      ...data,
      request_id: tarifChoice.request_id,
    });
    yield put(Creators.buyVignetLoginSuccess(response.data));
    history.push('/buy/vignette/annual/personal');
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetLoginFailure(error_message));
  }
}

export function* watchBuyVignetLogin() {
  yield takeLeading(Types.BUY_VIGNET_LOGIN_REQUEST, logIn);
}

export function* createAccount(actions) {
  try {
    const { data } = actions;
    const tarifChoice = JSON.parse(sessionStorage.getItem('tarifChoices'));
    const response = yield call(buyVigneteCreateAccountApi, {
      ...data,
      request_id: tarifChoice.request_id,
    });
    yield put(Creators.buyVignetCreateAccountSuccess(response.data));
    history.push('/buy/vignette/annual/personal');
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetCreateAccountFailure(error_message));
  }
}

export function* watchBuyVignetCreateAccount() {
  yield takeLeading(Types.BUY_VIGNET_CREATE_ACCOUNT_REQUEST, createAccount);
}

export function* getOwner(actions) {
  try {
    const response = yield call(buyVigneteGetOwnerApi);
    yield put(Creators.buyVignetGetOwnerSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetGetOwnerFailure(error_message));
  }
}

export function* watchBuyVignetGetOwner() {
  yield takeLeading(Types.BUY_VIGNET_GET_OWNER_REQUEST, getOwner);
}

export function* updateOwner(actions) {
  try {
    const { data } = actions;
    const response = yield call(buyVignetUpdateOwnerApi, data);
    yield put(Creators.buyVignetUpdateOwnerSuccess(response.data));
    const tarifChoice = JSON.parse(sessionStorage.getItem('tarifChoices'));
    if (tarifChoice.documents && tarifChoice.documents.length) {
      history.push('/buy/vignette/annual/upload-files');
    } else {
      history.push('/buy/vignette/annual/overview');
    }
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetUpdateOwnerFailure(error_message));
  }
}

export function* watchBuyVignetUpdateOwner() {
  yield takeLeading(Types.BUY_VIGNET_UPDATE_OWNER_REQUEST, updateOwner);
}

export function* getObjectTypes(actions) {
  try {
    const response = yield call(buyGetObjectTypesApi);
    yield put(Creators.buyVignetGetObjectTypesSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetGetObjectTypesFailure(error_message));
  }
}

export function* watchBuyVignetGetObjectTypes() {
  yield takeLeading(Types.BUY_VIGNET_GET_OBJECT_TYPES_REQUEST, getObjectTypes);
}

export function* getDriveTypes(actions) {
  try {
    const response = yield call(buyGetDriveTypesApi);
    yield put(Creators.buyVignetGetDriveTypesSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetGetDriveTypesFailure(error_message));
  }
}

export function* watchBuyVignetGetDriveTypes() {
  yield takeLeading(Types.BUY_VIGNET_GET_DRIVE_TYPES_REQUEST, getDriveTypes);
}

export function* createDayRequest(actions) {
  try {
    const { data } = actions;
    const response = yield call(buyVignetCreateDayRequestApi, data);
    yield put(Creators.buyVignetCreateDaySuccess(response.data));
    history.push('/buy/vignette/one-day/overview/display');
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetCreateDayFailure(error_message));
  }
}

export function* watchBuyVignetCreateDayRequest() {
  yield takeLeading(Types.BUY_VIGNET_CREATE_DAY_REQUEST, createDayRequest);
}

export function* getBankLists(actions) {
  try {
    const { data } = actions;
    const response = yield call(buyGetBankListsApi, data);
    yield put(Creators.buyVignetGetBankListsSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetGetBankListsFailure(error_message));
  }
}

export function* watchBuyVignetBankLists() {
  yield takeLeading(Types.BUY_VIGNET_GET_BANK_LISTS_REQUEST, getBankLists);
}

export function* makeDayPayment(actions) {
  try {
    const { issuer } = actions;
    const response = yield call(buyMakeDayPaymentApi, {
      issuer,
      return_url_success: `${CURRENT_URL}/buy/vignette/one-day/success`,
      return_url_cancel: `${CURRENT_URL}/buy/vignette/one-day/overview/cancel`,
      return_url_error: `${CURRENT_URL}/buy/vignette/one-day/overview/error`,
      return_url_reject: `${CURRENT_URL}/buy/vignette/one-day/overview/reject`,
    });
    yield put(Creators.buyVignetMakeDayPaymentSuccess(response.data));
    window.location = response.data.redirect_url;
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetMakeDayPaymentFailure(error_message));
  }
}

export function* watchBuyVignetMakeDayPayment() {
  yield takeLeading(Types.BUY_VIGNET_MAKE_DAY_PAYMENT_REQUEST, makeDayPayment);
}

export function* getOverview(actions) {
  try {
    const response = yield call(buyGetOverviewApi);
    yield put(Creators.buyVignetGetOverviewSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetGetOverviewFailure(error_message));
  }
}

export function* watchBuyVignetGetOverview() {
  yield takeLeading(Types.BUY_VIGNET_GET_OVERVIEW_REQUEST, getOverview);
}

export function* uploadDocuments(actions) {
  try {
    const { data } = actions;
    const response = yield call(buyUploadDocumentsApi, data);
    yield put(Creators.buyVignetUploadDocumentsSuccess(response.data));
    history.push('/buy/vignette/annual/overview');
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetUploadDocumentsFailure(error_message));
  }
}

export function* watchBuyVignetUploadDocuments() {
  yield takeLeading(Types.BUY_VIGNET_UPLOAD_DOCUMENTS_REQUEST, uploadDocuments);
}

export function* hasMandate(actions) {
  try {
    const response = yield call(buyHasMandateApi);
    yield put(Creators.buyVignetHasMandateSuccess(response.data));
    if (response.data.has_mandate) {
      history.push('/buy/vignette/annual/payment-choice/display');
    } else {
      history.push('/buy/vignette/annual/mandate');
    }
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetHasMandateFailure(error_message));
  }
}

export function* watchBuyVignetHasMandate() {
  yield takeLeading(Types.BUY_VIGNET_HAS_MANDATE_REQUEST, hasMandate);
}

export function* createMandate(actions) {
  try {
    const { issuer } = actions;
    const response = yield call(buyCreateMandateApi, {
      issuer,
      return_url_success: `${CURRENT_URL}/buy/vignette/annual/payment-choice/display`,
      return_url_cancel: `${CURRENT_URL}/buy/vignette/annual/payment-instruction/cancel`,
      return_url_error: `${CURRENT_URL}/buy/vignette/annual/payment-instruction/error`,
      return_url_reject: `${CURRENT_URL}/buy/vignette/annual/payment-instruction/reject`,
    });
    yield put(Creators.buyVignetCreateMandateSuccess(response.data));
    window.location = response.data.redirect_url;
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetCreateMandateFailure(error_message));
  }
}

export function* watchBuyVignetCreateMandate() {
  yield takeLeading(Types.BUY_VIGNET_CREATE_MANDATE_REQUEST, createMandate);
}

export function* getInstallments(actions) {
  try {
    const response = yield call(buyGetInstallmentsApi);
    yield put(Creators.buyVignetGetInstallmentsSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetGetInstallmentsFailure(error_message));
  }
}

export function* watchBuyVignetGetInstallments() {
  yield takeLeading(Types.BUY_VIGNET_GET_INSTALLMENTS_REQUEST, getInstallments);
}

export function* getVignetCost(actions) {
  try {
    const response = yield call(buyGetVignetCostApi);
    yield put(Creators.buyVignetGetVignetCostSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetGetVignetCostFailure(error_message));
  }
}

export function* watchBuyVignetGetVignetCost() {
  yield takeLeading(Types.BUY_VIGNET_GET_VIGNET_COST_REQUEST, getVignetCost);
}

export function* createDirect(actions) {
  try {
    const { issuer } = actions;
    const response = yield call(buyCreateDirectApi, {
      issuer,
      return_url_success: `${CURRENT_URL}/buy/vignette/annual/success`,
      return_url_cancel: `${CURRENT_URL}/buy/vignette/annual/payment-choice/cancel`,
      return_url_error: `${CURRENT_URL}/buy/vignette/annual/payment-choice/error`,
      return_url_reject: `${CURRENT_URL}/buy/vignette/annual/payment-choice/reject`,
    });
    yield put(Creators.buyVignetCreateDirectSuccess(response.data));
    sessionStorage.setItem('request_number', response.data.request_number);
    if (response.data.redirect_url) {
      window.location = response.data.redirect_url;
    } else {
      const documents = JSON.parse(sessionStorage.getItem('tarifChoices'));
      if (documents && documents.documents && documents.documents.length) {
        sessionStorage.setItem('request_number', response.data.request_number);
        history.push('/buy/vignette/annual/success', {
          id: 'buy.vignet.successful.message',
          titleId: 'buy.vignete.instruction.title',
          variable: response.data.request_number,
        });
      } else {
        history.push('/buy/vignette/annual/success', {
          id: 'buy.vignet.success.no.documents.message',
          titleId: 'buy.vignete.instruction.title',
          variable: response.data.request_number,
        });
      }
    }
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetCreateDirectFailure(error_message));
  }
}

export function* watchBuyVignetCreateDirect() {
  yield takeLeading(Types.BUY_VIGNET_CREATE_DIRECT_REQUEST, createDirect);
}

export function* createInstallment(actions) {
  try {
    const response = yield call(buyCreateInstallmentApi, {
      return_url_success: `${CURRENT_URL}/buy/vignette/payment/direct/`,
      return_url_cancel: `${CURRENT_URL}/buy/vignette/payment/direct-cancel/`,
      return_url_error: `${CURRENT_URL}/buy/vignette/payment/direct-error/`,
      return_url_reject: `${CURRENT_URL}/buy/vignette/payment/direct-reject/`,
    });
    yield put(Creators.buyVignetCreateInstallmentSuccess(response.data));
    const documents = JSON.parse(sessionStorage.getItem('tarifChoices'));
    sessionStorage.setItem('request_number', response.data.request_number);
    if (documents && documents.documents && documents.documents.length) {
      history.push('/buy/vignette/annual/success', {
        id: 'buy.vignet.successful.message',
        titleId: 'buy.vignete.instruction.title',
        variable: response.data.request_number,
      });
    } else {
      history.push('/buy/vignette/annual/success', {
        id: 'buy.vignet.success.no.documents.message',
        titleId: 'buy.vignete.instruction.title',
        variable: response.data.request_number,
      });
    }
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetCreateInstallmentFailure(error_message));
  }
}

export function* watchBuyVignetCreateInstallment() {
  yield takeLeading(
    Types.BUY_VIGNET_CREATE_INSTALLMENT_REQUEST,
    createInstallment,
  );
}

export function* checkDay(actions) {
  try {
    const { data } = actions;
    const response = yield call(buyCheckDayRequestApi, data);
    yield put(Creators.buyVignetCheckDaySuccess(response.data));
    history.push('/buy/vignette/one-day/tarif');
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetCheckDayFailure(error_message));
  }
}

export function* watchBuyVignetCheckDay() {
  yield takeLeading(Types.BUY_VIGNET_CHECK_DAY_REQUEST, checkDay);
}

export function* confirmation(actions) {
  try {
    const response = yield call(buyGetVignetConfirmationApi);
    yield put(Creators.buyVignetConfirmationSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetConfirmationFailure(error_message));
  }
}

export function* watchBuyVignetConfirmation() {
  yield takeLeading(Types.BUY_VIGNET_CONFIRMATION_REQUEST, confirmation);
}

export function* forgotPassword(actions) {
  try {
    const { data } = actions;
    const response = yield call(buyVignetForgotPasswordApi, data);
    yield put(Creators.buyVignetForgotPasswordSuccess(response.data));
    alert('Controleer uw e-mail');
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetForgotPasswordFailure(error_message));
  }
}

export function* watchBuyVignetForgotPassword() {
  yield takeLeading(Types.BUY_VIGNET_FORGOT_PASSWORD_REQUEST, forgotPassword);
}

export function* resetPassword(actions) {
  try {
    const { data } = actions;
    const response = yield call(buyVignetResetPasswordApi, data);
    yield put(Creators.buyVignetResetPasswordSuccess(response.data));
    logOut();
    history.push('/buy/vignette/annual/tarif');
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.buyVignetResetPasswordFailure(error_message));
  }
}

export function* watchBuyVignetResetPassword() {
  yield takeLeading(Types.BUY_VIGNET_RESET_PASSWORD_REQUEST, resetPassword);
}
