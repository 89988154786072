import React from 'react';
import { Row, Col, Input, Checkbox, Form, Alert, Select } from 'antd';
import { FormattedMessage } from 'react-intl';

import './styles.scss';
import { EXTEND_VIGNETTE_ROUTE, DELAY_SECONDS } from 'constants/index';
import StepCard from 'components/StepCard';
import COUNTRY_CODE from 'constants/countryCode';

let timer;
class ExtendCustomerInfo extends React.Component {
  state = {
    visible: true,
  };
  componentDidMount() {
    if (this.props.getOwner) {
      this.props.getOwner();
      return;
    }
    this.props.getCustomer(this.props.customer_id);
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.formIdentifier) {
          sessionStorage.setItem(
            [this.props.formIdentifier],
            JSON.stringify(values),
          );
        }
        this.setState({ visible: true });
        if (this.props.updateOwner) {
          this.props.updateOwner(values);
        } else if (this.props.createDetails) {
          sessionStorage.setItem('customer', JSON.stringify(values));
          this.props.createDetails(values);
        } else {
          this.props.updateCustomer(values);
        }
      }
    });
  };

  checkCheckBox = (rule, value, callback) => {
    if (!value) {
      callback(
        <Col className="push-left">
          <FormattedMessage id="help.confirm.this" />
        </Col>,
      );
    } else {
      callback();
    }
  };

  compareToFirstEmail = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('email')) {
      callback(<FormattedMessage id="emailNotMatch" />);
    } else {
      callback();
    }
  };

  renderServerErrorMsg = () => {
    const { updateError } = this.props;
    if (updateError) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, DELAY_SECONDS);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={updateError} type="error" />
          </div>
        );
      }
    }
  };

  renderCountryCode = () => {
    const { getFieldDecorator } = this.props.form;
    const prefixSelector = (
      <Select style={{ width: 70 }}>
        {COUNTRY_CODE.map(data => {
          return (
            <Select.Option value={data.number}>{data.number}</Select.Option>
          );
        })}
      </Select>
    );

    return prefixSelector;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let { customer, isUpdating } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          steps={this.props.steps || 7}
          current={this.props.current === 0 ? 0 : this.props.current || 3}
          isNextLoading={isUpdating}
          onPrevious={() =>
            this.props.onPrevious
              ? this.props.onPrevious()
              : this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.CHOICE_ROUTE)
          }
          onNext={this.handleSubmit}
          id={
            this.props.title ? this.props.title : 'extend.step.personal.title'
          }
          className="customer-info"
          isLoading={this.props.isGettingInfo}
        >
          <Row className="spacing">
            <div>
              <p className="title">
                <FormattedMessage
                  id={
                    this.props.formTitle
                      ? this.props.formTitle
                      : 'extend.step.personal.info'
                  }
                />
              </p>
            </div>
            <Row gutter={4}>
              <Col span={24} xs={24} lg={12} md={24} sm={24}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="lastname" />*
                  </label>
                  <Form.Item>
                    {getFieldDecorator('last_name', {
                      initialValue: customer.last_name,
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage id="required.error" />,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} xs={12} lg={6} md={12} sm={12}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="insertion" />
                  </label>
                  <Form.Item>
                    {getFieldDecorator('prefix', {
                      initialValue: customer.prefix || '',
                      rules: [{ required: false }],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} xs={12} lg={6} md={12} sm={12}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="intial" />*
                  </label>
                  <Form.Item>
                    {getFieldDecorator('initials', {
                      initialValue: customer.initials,
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage id="required.error" />,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={4}>
              <Col span={24} xs={24} lg={12} md={24} sm={24}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="address" />*
                  </label>
                  <Form.Item>
                    {getFieldDecorator('address', {
                      initialValue: customer.address,
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage id="required.error" />,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} xs={12} lg={4} md={12} sm={12}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="house.number" />*
                  </label>
                  <Form.Item>
                    {getFieldDecorator('number', {
                      initialValue: customer.number,
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage id="required.error" />,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} xs={12} lg={4} md={12} sm={12}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="addition" />
                  </label>
                  <Form.Item>
                    {getFieldDecorator('addition', {
                      initialValue: customer.addition || '',
                      rules: [{ required: false }],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} xs={24} lg={4} md={24} sm={24}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="postal.code" />*
                  </label>
                  <Form.Item>
                    {getFieldDecorator('postcode', {
                      initialValue: customer.postcode,
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage id="required.error" />,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={24} xs={24} lg={8} md={24} sm={24}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="place" />*
                  </label>
                  <Form.Item>
                    {getFieldDecorator('city', {
                      initialValue: customer.city,
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage id="required.error" />,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} xs={24} lg={8} md={24} sm={24}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="telephone.number" />
                  </label>
                  <Form.Item>
                    {getFieldDecorator('phone', {
                      initialValue: customer.phone,
                      rules: [{ required: false }],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
              <Col span={24} xs={24} lg={8} md={24} sm={24}>
                <div className="input-wrapper">
                  <label>
                    <FormattedMessage id="mobile" />*
                  </label>
                  <Form.Item>
                    {getFieldDecorator('mobile_phone', {
                      initialValue: customer.mobile_phone,
                      rules: [
                        {
                          required: true,
                          message: <FormattedMessage id="required.error" />,
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </div>
              </Col>
              {this.props.getOwner && !this.props.newDayVignet ? (
                <Col span={24} xs={24} lg={8} md={24} sm={24}>
                  <div className="input-wrapper">
                    <label>
                      <FormattedMessage id="bsn" />*
                    </label>
                    <Form.Item>
                      {getFieldDecorator('bsn', {
                        initialValue: customer.bsn,
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="required.error" />,
                          },
                        ],
                      })(<Input />)}
                    </Form.Item>
                  </div>
                </Col>
              ) : null}
              {this.props.newDayVignet ? (
                <React.Fragment>
                  <Col span={24} xs={24} lg={12} md={24} sm={24}>
                    <div className="input-wrapper">
                      <label>
                        <FormattedMessage id="email" />*
                      </label>
                      <Form.Item>
                        {getFieldDecorator('email', {
                          initialValue: customer.email,
                          rules: [
                            {
                              required: true,
                              message: <FormattedMessage id="required.error" />,
                            },
                            {
                              type: 'email',
                              message: (
                                <FormattedMessage id="email.regex.error" />
                              ),
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={24} xs={24} lg={12} md={24} sm={24}>
                    <div className="input-wrapper">
                      <label>
                        <FormattedMessage id="confirmEmail" />*
                      </label>
                      <Form.Item>
                        {getFieldDecorator('confirm_email', {
                          initialValue: customer.email,
                          rules: [
                            {
                              required: true,
                              message: <FormattedMessage id="required.error" />,
                            },
                            {
                              type: 'email',
                              message: (
                                <FormattedMessage id="email.regex.error" />
                              ),
                            },
                            {
                              validator: this.compareToFirstEmail,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </div>
                  </Col>
                </React.Fragment>
              ) : null}
            </Row>
            <Row>
              {!this.props.newDayVignet ? (
                <Form.Item>
                  {getFieldDecorator('checkbox', {
                    valuePropName: 'checked',
                    rules: [{ validator: this.checkCheckBox }],
                  })(
                    <Checkbox required className="input-wrapper">
                      <FormattedMessage id="extend.step.personal.info.checkbox.message" />
                    </Checkbox>,
                  )}
                </Form.Item>
              ) : null}
              {this.renderServerErrorMsg()}
            </Row>
          </Row>
        </StepCard>
      </Form>
    );
  }
}

const CustomerInfoForm = Form.create({ name: 'CustomerInfoForm' })(
  ExtendCustomerInfo,
);
export default CustomerInfoForm;
